import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~11,[2]],
		"/(app)/equipo": [12,[2,3]],
		"/(app)/equipo/arbitros": [~13,[2,3]],
		"/(app)/equipo/arbitros/[slug]": [~14,[2,4],[,5]],
		"/(app)/equipo/luchadores": [~15,[2,3]],
		"/(app)/equipo/luchadores/[slug]": [~16,[2,6],[,7]],
		"/(app)/equipo/presentadores": [~17,[2,3]],
		"/(app)/equipo/presentadores/[slug]": [~18,[2,8],[,9]],
		"/(app)/error": [19,[2]],
		"/(app)/eventos": [~20,[2]],
		"/(app)/eventos/[slug]": [~21,[2],[,10]],
		"/(app)/exito": [~22,[2]],
		"/(studio)/studio/[...catchall]": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';